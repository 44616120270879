import '../assets/images/favicon.png';
import '../assets/images/appicon.png';
import '../assets/images/socialicon.png';
import '../assets/images/seal.png';

import swRuntime from 'serviceworker-webpack-plugin/lib/runtime';
import './polyfills';

// import * as contentScroll from './components/content-scroll';
// import * as navigation from './components/navigation';
// import * as mobileNavigation from './components/navigation-mobile';
import * as menu from './components/menu';

// contentScroll.init();
// navigation.init();
// mobileNavigation.init();
menu.init();

if ('serviceWorker' in navigator) {
    /**
     * Activate to register the service worker
     */
    // swRuntime.register();
}
