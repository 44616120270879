export const init = () => {
  const $pageMenu = document.querySelector('#page-menu');
  const $submenuToggles = document.querySelectorAll('.page-menu__submenu__toggle');

  if ($pageMenu) {
    const $menuToggles = document.querySelectorAll('.toggle-menu');
    const $menuToggle = document.querySelector('#toggle-menu');

    Array.prototype.forEach.call($menuToggles, $element =>
      $element.addEventListener('click', () => $pageMenu.classList.toggle('page-menu--active')
    ));

    Array.prototype.forEach.call($menuToggles, $element =>
      $element.addEventListener('click', () => $menuToggle.classList.toggle('active')
    ));
  }

  if ($submenuToggles) {
    Array.prototype.forEach.call($submenuToggles, $element =>
      $element.addEventListener('click', () => $element.parentElement.classList.toggle('page-menu__submenu--active')
    ));
  }
};
